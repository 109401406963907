import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import breadcrumbArrowBlack from "../../resources/icons/breadcrumbArrowBlack.svg";
import breadcrumbArrowGray from "../../resources/icons/breadcrumbArrowGray.svg";
import CurrencyIcon from "../../resources/icons/money.svg";
import AuthContext from "../../store/auth-context";
import WarningIcon from "../../resources/icons/warning-triangle.svg";

const CfBreadcrumb = ({
  breadcrumbItems,
  isToggleOn,
  setIsToggleOn,
  marginTop,
  creditCount,
  isPostPaid,
}) => {
  const { t, i18n } = useTranslation();
  const authContext = useContext(AuthContext);

  return (
    <nav
      className={`${
        isPostPaid
          ? "ls afa afp alj h-[4rem] bg-cfBreadcrumbBG"
          : "flex flex-col md:flex-row ls afa afp alj h-[8rem] md:h-[4rem] md:justify-between bg-cfBreadcrumbBG"
      }`}
      aria-label="Breadcrumb"
    >
      <ol
        role="list"
        className={`${
          isPostPaid
            ? "flex flex-col sm:flex-row justify-between md:w-[37%]"
            : "flex flex-col sm:flex-row justify-between h-[4rem] gap-2 md:gap-0 md:w-[37%]"
        }`}
      >
        <div className="gs ls ti abe ard cex ddc">
          <li key="home" className="ls">
            <div className="ls yu">
              <a className="axk bks">
                <NavLink to={"/"} className={`hover:no-underline`}>
                  <span className="text-cfTextNavbarDisabled ">
                    {"Templates"}
                  </span>
                </NavLink>
              </a>
            </div>
          </li>
          {breadcrumbItems.map((item, index) => {
            return (
              <li key={item.title} className="ls">
                <div className="ls yu">
                  <img
                    src={
                      window.location.pathname === item.to
                        ? breadcrumbArrowBlack
                        : breadcrumbArrowGray
                    }
                    alt=""
                  />
                  <a className={`js avv avz axm bku`}>
                    <NavLink
                      className={`hover:no-underline ${
                        window.location.pathname === item.to
                          ? "font-bold text-cfTextBreadcrumbActive hover:text-cfTextNavbarActive"
                          : "text-cfTextNavbarDisabled"
                      }`}
                      to={item.to}
                    >
                      {" "}
                      {item.title}
                    </NavLink>
                  </a>
                </div>
              </li>
            );
          })}
        </div>
        <div className="w-[38%]">
          <div
            className={`flex xs:flex-row ml-[1rem] sm:ml-[0rem] ${marginTop} sm:mt-[1.19rem]`}
          >
            <label className="inline-flex cursor-pointer xs:pb-6 md:pb-0">
              <input
                type="checkbox"
                id="toggle-prefill"
                checked={isToggleOn}
                onChange={() => setIsToggleOn((prev) => !prev)}
                className="sr-only peer"
              />
              <div className="relative w-11 mb-4 md:mb-12 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer dark:bg-gray-200 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-cfPrimaryLight"></div>
              <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
            </label>
            <div className="flex flex-col ">
              <div className="font-medium text-slate-700">Prefill</div>
            </div>
          </div>
        </div>
      </ol>
      {!isPostPaid &&
        (creditCount === 1 ? (
          <div className="flex justify-center self-start md:self-auto bg-white ml-[1rem] sm:ml-[1.7rem] md:ml-[0rem] mr-[0rem] md:mr-[2.8rem] gap-2 mt-[0.5rem] mb-[0.5rem] min-w-[220px] rounded-lg shadow-gray-500 shadow-md px-2 py-1 md:py-0">
            <img src={CurrencyIcon} height={28} width={28} alt="" />
            <div className="flex items-center gap-2">
              <div className="font-bold text-cfPrimaryLight text-[24px]">
                {creditCount}
              </div>
              <div className="font-semibold text-[#4F5768]">
                {" "}
                credit will be used.
              </div>
            </div>
          </div>
        ) : creditCount === "Insufficient Credits" ||
          authContext.creditCount === "Insufficient Credits" ? (
          <div className="flex justify-center self-start md:self-auto bg-[#FFCBCB] ml-[1rem] sm:ml-[1.7rem] md:ml-[0rem] mr-[0rem] md:mr-[2.8rem] gap-2 mt-[0.5rem] mb-[0.5rem] min-w-[220px] rounded-lg shadow-gray-500 px-2 py-2 md:py-0">
            <img src={WarningIcon} height={30} width={30} alt="" />
            <div className="flex items-center gap-2">
              <div className="font-semibold text-[#D92D20] text-[18px]">
                Insufficient Credits
              </div>
              {/* <div className="bg-[#3538CD] cursor-pointer px-[0.32rem] py-[0.45rem] rounded-[7px] text-[13px] font-semibold text-white">
              Buy Credits
            </div> */}
            </div>
          </div>
        ) : (
          authContext.isFileUploaded && (
            <div className="flex justify-center self-start md:self-auto bg-white ml-[1rem] sm:ml-[1.7rem] md:ml-[0rem] mr-[0rem] md:mr-[2.8rem] gap-2 mt-[0.5rem] mb-[0.5rem] min-w-[220px] rounded-lg shadow-gray-500 shadow-md px-2 py-1 md:py-0">
              <img src={CurrencyIcon} height={28} width={28} alt="" />
              <div className="flex items-center gap-2">
                <div className="font-bold text-cfPrimaryLight text-[24px]">
                  {creditCount}
                </div>
                <div className="font-semibold text-[#4F5768]">
                  {" "}
                  credits will be used.
                </div>
              </div>
            </div>
          )
        ))}
    </nav>
  );
};

export default CfBreadcrumb;
