import * as React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { GET, POST } from "../../service/CRUDService";
import EntityConstants from "../../configs/EntityConstants";
import SettingOption from "../../components/SettingOption/SettingOption";
import { CgAttachment } from "react-icons/cg";
import Toast from "../../common/Toast/Toast";
import convertToBase64 from "../../utils/ConvertToBase64";
import PeviewImage from "../../resources/icons/previewImageLogo.svg";
import CopyToClipBoard from "../../utils/CopyToClipBoard";
import correct from "../../resources/icons/correct.svg";
import Copy from "../../resources/icons/copy.svg";
import qrCodeIcon from "../../resources/icons/qrcode.svg";
import LinkedInIcon from "../../resources/icons/linkedin-outline.svg";
import LinkedInLiveIcon from "../../resources/icons/linkedin.svg";
import SettingsIcon from "../../resources/icons/circular-settings.svg";
import NotificationIcon from "../../resources/icons/notification.svg";
import QrCodeIcon from "../../resources/icons/qr-code.svg";
import BackArrowIcon from "../../resources/icons/breadcrumbArrowGray.svg";
import MailIcon from "../../resources/icons/email-setting.svg";
import { BtnSave, BtnVerifyEmailSave } from "../SettingTemplate/SettingButton";
import SettingEmailAddress from "./SettingEmailAddress";
import EmailTemplateEditor from "../EmailTemplateEditor/EmailTemplateEditor";

function LogoUpload({
  id,
  altText,
  onFileUpload,
  logoHeading,
  logoSubHeading,
}) {
  const [dragActive, setDragActive] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    processFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);

    const file = event.dataTransfer.files[0];
    processFile(file);
  };

  const processFile = (file) => {
    const allowedFileTypes = ["svg", "png", "jpeg", "jpg"];
    console.log("The file is : ", file);

    if (file) {
      const extension = file.name.split(".").pop().toLowerCase();
      const isValid = allowedFileTypes.includes(extension);

      if (isValid) {
        onFileUpload(file);
        console.log("the output is : ", isValid);
      } else if (!isValid) {
        console.log("Give a proper format", isValid);
        Toast({
          type: "error",
          title: "Error",
          message: "Please select a valid image format (SVG, PNG, JPEG, JPG)",
        });
      }
    } else {
      console.log("File not found");
      Toast({
        type: "error",
        title: "Error",
        message: "Please select an image",
      });
    }
  };

  return (
    <div
      className={`flex gap-4 py-6 px-4 mt-2 cursor-pointer text-gray-500 bg-white rounded-lg outline-dotted outline-gray-300 outline-offset-2 shadow-sm ${
        dragActive ? "bg-gray-100" : ""
      }`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={() => document.getElementById(id).click()}
    >
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        className="hidden"
        id={id}
        loading="lazy"
      />
      <label className="cursor-pointer flex flex-row">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e6c376ce7ba432a6c2a0781570893c28440a280d4825eeedee07208e78e63d79?apiKey=1ca7de1f58f745cd95efc0640d2ef737&"
          alt={altText}
          className="shrink-0 my-auto w-9 mr-4 aspect-square"
        />
        <div className="flex flex-col">
          <div className="text-sm">
            <span className="font-semibold text-indigo-700">
              {logoHeading} {logoSubHeading}
            </span>
          </div>
          <div className="mt-2 text-xs">SVG, PNG, JPEG (max 800x900px)</div>
        </div>
      </label>
    </div>
  );
}

function SettingTemplate() {
  const [orgDetails, setOrgDetails] = useState({
    org_website_url: "",
    org_domain: "",
    org_logo_hyperlink: "",
    org_logo: null,
    enable_whatsapp_notification: false,
    enable_email_notification: false,
    org_qrcode_logo: null,
    org_did_key: null,
    org_did_image: null,
    org_linkedin_code: null,
    org_linkedin_name: null,
    org_facebook_url: "",
    org_twitter_url: "",
    org_instagram_url: "",
    org_youtube_url: "",
    org_whatsapp_url: "",
    org_linkedin_url: "",
  });

  const [logoBase64, setLogoBase64] = useState("");
  const [qrCodeLogoBase64, setQrCodeLogoBase64] = useState("");
  const [isFormDataChanged, setIsFormDataChanged] = useState(false);
  const [copied, setCopied] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("Organization Settings");
  const [initialOrgDetails, setInitialOrgDetails] = useState({});
  const [isAccordionOpen, setIsAccordionOpen] = useState(false); // Manage accordion state
  const [selectedSubMenu, setSelectedSubMenu] = useState("Email Template");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isEmail, setIsEmail] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isShowWarning, setIsShowWarning] = useState(false);
  const [isEmptyEmail, setIsEmptyEmail] = useState(false);
  const [testEmail, setTestEmail] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeSection = queryParams.get("section");

  const sendTestEmail = async () => {
    try {
      const response = await GET(EntityConstants.CURRENT_USER);
      const data = await response.json();
      if (response.ok) {
        setTestEmail(data.email);
      } else {
        Toast({
          type: "error",
          title: "Error",
          message: data.error,
        });
      }
    } catch (error) {
      Toast({
        type: "error",
        title: "Error",
        message: "An error occurred while sending the test email.",
      });
    }
  };

  useEffect(() => {
    sendTestEmail();
  }, []);

  const fetchOrgEmailDetails = async () => {
    try {
      const emailDetailsResponse = await GET(EntityConstants.GET_EMAIL_DETAILS);
      const data = await emailDetailsResponse.json();
      if (!data || !data.email) {
      }
      setIsEmail(data.email);
      setIsEmailValid(validateEmail(data.email));
      setIsEmptyEmail(false);
      switch (data.status) {
        case "pending":
          setIsVerified(false);
          setIsEmailSent(false);
          setIsShowWarning(false);
          break;
        case "failed":
          setIsVerified(false);
          setIsEmailSent(false);
          setIsShowWarning(true);
          break;
        case "verified":
          setIsVerified(true);
          setIsEmailSent(true);
          setIsShowWarning(false);
          break;
        default:
          console.log("Unwanted status>>>>>", data.status);
          setIsEmptyEmail(true);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setIsEmptyEmail(true);
        setIsEmail("");
      } else {
        Toast({
          type: "error",
          title: "Error",
          message: error.error,
        });
      }
    }
  };

  const handleVerifyEmail = async () => {
    try {
      const response = await POST(EntityConstants.POST_EMAIL_DETAILS, {
        email: isEmail,
      });

      if (response.ok) {
        setIsEmailSent(true);
        const data = await response.json();
        Toast({
          type: "success",
          title: "Success",
          message: data.message,
        });
        setIsFormDataChanged(false);
        fetchOrgEmailDetails();
      } else {
        const errorData = await response.json();
        Toast({
          type: "error",
          title: "Error",
          message: errorData.error,
        });
      }
    } catch (error) {
      Toast({
        type: "error",
        title: "Error",
        message: "An error occurred while verifying the email.",
      });
    }
  };
  const validateEmail = (email) => {
    if (!email) return true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    async function fetchOrgDetails() {
      try {
        const response = await GET(EntityConstants.GET_ORG_DETAILS);
        const data = await response.json();
        const {
          tenant_name,
          organisation_name,
          organisation_id,
          org_website_url,
          org_domain,
          org_logo_hyperlink,
          org_logo,
          enable_whatsapp_notification,
          enable_email_notification,
          org_qrcode_logo,
          created_by_name,
          updated_by_name,
          org_did_key,
          org_did_image,
          org_linkedin_code,
          org_linkedin_name,
          org_facebook_url,
          org_twitter_url,
          org_instagram_url,
          org_youtube_url,
          org_whatsapp_url,
          org_linkedin_url,
        } = data;
        setOrgDetails({
          tenant_name,
          organisation_name,
          organisation_id,
          org_website_url,
          org_domain,
          org_logo_hyperlink,
          org_logo,
          enable_whatsapp_notification,
          enable_email_notification,
          org_qrcode_logo,
          created_by_name,
          updated_by_name,
          org_did_key,
          org_did_image,
          org_linkedin_code,
          org_linkedin_name,
          org_facebook_url,
          org_twitter_url,
          org_instagram_url,
          org_youtube_url,
          org_whatsapp_url,
          org_linkedin_url,
        });
        setInitialOrgDetails(data);
      } catch (error) {
        console.error("Error fetching organization details:", error);
      }
    }
    fetchOrgDetails();
  }, []);

  useEffect(() => {
    fetchOrgEmailDetails();
  }, []);

  const handleMenuChange = (menu) => {
    if (isFormDataChanged && menu !== selectedMenu) {
      const confirmLeave = window.confirm(
        "Changes that you made may not be saved."
      );
      if (confirmLeave) {
        setOrgDetails(initialOrgDetails);
        setIsFormDataChanged(false);
      } else {
        return;
      }
    }
    if (menu === "Email Settings") {
      setIsAccordionOpen((prev) => {
        if (!prev) {
          setSelectedSubMenu("Email Template");
        }
        return !prev;
      });
      setSelectedMenu(menu);
    } else {
      setIsAccordionOpen(false);
      setSelectedMenu(menu);
    }
  };

  const handleSubMenuChange = (subMenu) => {
    setSelectedSubMenu(subMenu);
  };

  useEffect(() => {
    if (
      selectedMenu === "Email Settings" &&
      selectedSubMenu === "Email Address"
    ) {
      fetchOrgEmailDetails();
    }
  }, [selectedMenu, selectedSubMenu]);

  useEffect(() => {
    if (activeSection === "email-address") {
      setSelectedMenu("Email Settings");
      setSelectedSubMenu("Email Address");
      setIsAccordionOpen(true);
      fetchOrgEmailDetails();
    }
  }, [activeSection]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isFormDataChanged) {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDataChanged]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isFormDataChanged) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    const handlePopState = (event) => {
      if (isFormDataChanged) {
        const message =
          "You have unsaved changes, do you really want to leave?";
        if (!window.confirm(message)) {
          event.preventDefault();
          window.history.pushState(null, null, window.location.pathname);
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isFormDataChanged]);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
  }, []);

  const handlePostRequest = async (payload) => {
    try {
      const response = await POST(EntityConstants.POST_ORG_DETAILS, payload);
      if (response.ok) {
        Toast({
          type: "success",
          title: "Success",
          message: "Changes saved successfully",
        });
        setIsFormDataChanged(false);
      } else {
        const errorData = await response.json();
        if (errorData.message && Array.isArray(errorData.message)) {
          errorData.message.forEach((msg) => {
            Toast({
              type: "error",
              title: "Error",
              message: msg,
            });
          });
        } else {
          Toast({
            type: "error",
            title: "Error",
            message: "Error while processing the request",
          });
        }
      }
    } catch (error) {
      console.error("Error in POST request:", error);
    }
  };

  const handleInputChange = (e) => {
    setIsEmail(e.target.value);
    const { id, value } = e.target;
    if (id === "org_from_mail") {
      setIsEmailValid(validateEmail(value));
    }

    setOrgDetails((prevDetails) => ({
      ...prevDetails,
      [id]: value,
    }));
    setIsFormDataChanged(true);
  };

  // Handle file upload for organization logo
  const handleLogoUpload = async (file) => {
    const base64FileString = await convertToBase64(file);
    setLogoBase64(base64FileString);
    setOrgDetails((prevDetails) => ({
      ...prevDetails,
      org_logo: base64FileString,
    }));
    setIsFormDataChanged(true);
  };

  const handleQrCodeUpload = async (file) => {
    const base64FileString = await convertToBase64(file);
    setQrCodeLogoBase64(base64FileString);
    setOrgDetails((prevDetails) => ({
      ...prevDetails,
      org_qrcode_logo: base64FileString,
    }));
    setIsFormDataChanged(true);
  };

  // Handle toggle changes and immediately update the backend
  const handleToggleChange = async (toggleField) => {
    const newValue = !orgDetails[toggleField];
    setOrgDetails((prevDetails) => ({
      ...prevDetails,
      [toggleField]: newValue,
    }));
    setIsFormDataChanged(true);
  };

  const handleTestEmail = async () => {
    try {
      const response = await POST(EntityConstants.POST_TEST_EMAIL, {
        to_email: testEmail,
      });
      if (response.ok) {
        const data = await response.json();
        Toast({
          type: "success",
          title: "Success",
          message: data.message,
        });
        setIsFormDataChanged(false);
      } else {
        const errorData = await response.json();
        if (errorData.message && Array.isArray(errorData.message)) {
          errorData.message.forEach((msg) => {
            Toast({
              type: "error",
              title: "Error",
              message: msg,
            });
          });
        } else {
          Toast({
            type: "error",
            title: "Error",
            message: "Error while processing the request",
          });
        }
      }
    } catch (error) {
      console.error("Error in POST request:", error);
    }
  };

  const handleSave = async () => {
    let payload = {};
    switch (selectedMenu) {
      case "Organization Settings":
        payload = {
          org_domain: orgDetails.org_domain,
          org_logo_hyperlink: orgDetails.org_logo_hyperlink,
          org_logo: orgDetails.org_logo ? orgDetails.org_logo : null,
          org_facebook_url: orgDetails.org_facebook_url,
          org_twitter_url: orgDetails.org_twitter_url,
          org_instagram_url: orgDetails.org_instagram_url,
          org_youtube_url: orgDetails.org_youtube_url,
          org_whatsapp_url: orgDetails.org_whatsapp_url,
          org_linkedin_url: orgDetails.org_linkedin_url,
        };
        break;

      case "Organization Details":
        payload = {
          org_linkedin_name: orgDetails.org_linkedin_name,
          org_linkedin_code: orgDetails.org_linkedin_code,
        };
        break;

      case "Notification Settings":
        payload = {
          enable_whatsapp_notification: orgDetails.enable_whatsapp_notification,
          enable_email_notification: orgDetails.enable_email_notification,
        };
        break;

      case "Customize QR Code":
        payload = {
          org_qrcode_logo: orgDetails.org_qrcode_logo
            ? orgDetails.org_qrcode_logo
            : null,
        };
        break;

      default:
        console.error("Unrecognized section:", { selectedMenu });
        return;
    }

    handlePostRequest(payload);
  };

  const handleCopyClick = (event, id) => {
    event.stopPropagation();
    CopyToClipBoard(id);
    setCopied(true);
    setTimeout(() => setCopied(false), 1500);
  };

  const handleEmailChange = (e) => {
    const updatedEmail = e.target.value;
    setIsEmail(updatedEmail);
    setIsEmailValid(updatedEmail ? validateEmail(updatedEmail) : true);
    setIsFormDataChanged(true);
    setIsEmailSent(false);
  };

  const Menus = [
    { title: "Organization Settings", src: SettingsIcon },
    { title: "Organization Details", src: LinkedInIcon },
    { title: "Notification Settings", src: NotificationIcon },
    { title: "Customize QR Code", src: QrCodeIcon },
    { title: "Email Settings", src: MailIcon },
  ];

  return (
    <div className="min-h-screen bg-white flex flex-col pt-[6rem] w-full">
      <div className="ml-[1.125rem]">
        <header className="text-xl font-semibold text-gray-900">
          Settings
        </header>
        <section className="text-base leading-8 text-gray-900">
          You have admin controls enabled
        </section>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div
          className={`${
            selectedMenu ? "w-[95%] lg:w-[18%]" : "w-20"
          } bg-dark-purple h-full xl:h-screen ml-[1.125rem] relative transition-all duration-300`}
        >
          <ul>
            {Menus.map((Menu, index) => (
              <li
                key={index}
                onClick={() => handleMenuChange(Menu.title)}
                className={`flex rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 mt-2 hover:bg-[#F4F6F7] transition duration-500 ease-in-out ${
                  selectedMenu === Menu.title && "bg-[#F4F6F7]"
                }`}
              >
                <div
                  className={`bg-cfPrimaryLight rounded-3xl transition-all duration-50 ease-in-out ${
                    selectedMenu === Menu.title ? "w-1 h-7" : "w-0"
                  }`}
                ></div>
                <div className="w-[10%]">
                  <img src={`${Menu.src}`} height={60} width={60} alt="" />
                </div>
                <span
                  className={`${
                    selectedMenu
                      ? "text-gray-700 pt-1 flex w-[90%] justify-between font-normal origin-left duration-200"
                      : ""
                  }`}
                >
                  <div>{Menu.title}</div>
                  <div>
                    {Menu.title === "Email Settings" ? (
                      <img
                        src={BackArrowIcon}
                        alt="Toggle"
                        className={`transition-transform ${
                          isAccordionOpen ? "rotate-90" : ""
                        }`}
                      />
                    ) : (
                      <img src={BackArrowIcon} alt="" />
                    )}
                  </div>
                </span>
              </li>
            ))}
            {selectedMenu === "Email Settings" && (
              <div>
                <div
                  className={`accordion-content ${
                    isAccordionOpen ? "open" : ""
                  }`}
                >
                  <ul className="ml-[3.2rem] mt-1">
                    {[
                      { title: "Email Template" },
                      { title: "Email Address" },
                    ].map((subMenu, subIndex) => (
                      <li
                        key={subIndex}
                        onClick={() => handleSubMenuChange(subMenu.title)}
                        className={`py-2 mb-1 font-normal text-sm text-gray-700 cursor-pointer hover:bg-[#F4F6F7] transition duration-500 ease-in-out rounded-md ${
                          selectedSubMenu === subMenu.title
                            ? "bg-[#F4F6F7]"
                            : ""
                        }`}
                      >
                        <div className="flex items-center px-2 gap-x-4 justify-between">
                          <div
                            className={`bg-cfPrimaryLight rounded-3xl transition-all duration-50 ease-in-out ${
                              selectedSubMenu === subMenu.title
                                ? "w-1 h-6"
                                : "w-0"
                            }`}
                          ></div>
                          <span className="w-full">{subMenu.title}</span>
                          <img src={BackArrowIcon} alt="" />
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </ul>
        </div>
        <div className="h-full flex-1">
          {selectedMenu === "Email Settings" && (
            <div>
              {selectedSubMenu === "Email Template" && <EmailTemplateEditor />}
              {selectedSubMenu === "Email Address" && (
                <SettingEmailAddress
                  isEmail={isEmail}
                  handleEmailChange={handleEmailChange}
                  isEmailValid={isEmailValid}
                  isEmailSent={isEmailSent}
                  isVerified={isVerified}
                  handleTestEmail={handleTestEmail}
                  handleVerifyEmail={handleVerifyEmail}
                  isShowWarning={isShowWarning}
                  fetchOrgEmailDetails={fetchOrgEmailDetails}
                  isEmptyEmail={isEmptyEmail}
                />
              )}
            </div>
          )}
          {selectedMenu === "Organization Settings" && (
            <div className="flex flex-col">
              <div className="flex flex-col xl:flex-row w-full">
                <div className="p-3 xl:w-[50%]">
                  <div className="xs:pl-8 xs:pr-3 rounded-xl">
                    <header className="text-xl font-semibold mb-3 text-gray-900">
                      Organization Settings
                    </header>
                    <div className="mb-4">
                      <label
                        className="block mb-2 text-sm font-medium text-gray-900"
                        htmlFor="organisation_name"
                      >
                        Organization name
                      </label>
                      <input
                        type="text"
                        id="organisation_name"
                        value={orgDetails.organisation_name}
                        onChange={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="e.g. Credissuer"
                        disabled
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        className="block mb-2 text-sm font-medium text-gray-900"
                        htmlFor="org_website_url"
                      >
                        Organization website
                      </label>
                      <input
                        type="text"
                        id="org_website_url"
                        value={orgDetails.org_website_url}
                        onChange={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="e.g. www.credissuer.com"
                        disabled
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        className="block mb-2 text-sm font-medium text-gray-900"
                        htmlFor="org_domain"
                      >
                        Sub domain
                      </label>
                      <input
                        type="text"
                        id="org_domain"
                        value={orgDetails.org_domain}
                        onChange={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="e.g. org.ooru.io"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        className="block mb-2 text-sm font-medium text-gray-900"
                        htmlFor="org_logo_hyperlink"
                      >
                        Logo hyperlink
                      </label>
                      <input
                        type="text"
                        id="org_logo_hyperlink"
                        value={orgDetails.org_logo_hyperlink}
                        onChange={handleInputChange}
                        className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="e.g. https://www.credflow.com/templates/logo/"
                      />
                    </div>
                  </div>
                  <div className="w-full rounded-xl ">
                    <label class="block text-sm font-medium text-gray-900">
                      Organization Logo
                    </label>
                    <div className="gap-5 flex flex-col 2xl:flex-row">
                      <div className="items-center flex-initial w-full mt-[0rem] 2xl:mt-[1rem]">
                        <LogoUpload
                          id="org-logo-upload"
                          logoHeading={"Upload your"}
                          logoSubHeading={"organization's logo"}
                          altText="Upload QR code image"
                          onFileUpload={handleLogoUpload}
                        />
                      </div>
                      <div className="flex justify-start items-center">
                        <div className="flex justify-start items-center bg-gray-100 border-r-indigo-200 border-dashed border-2 rounded-xl w-[9rem] h-[9rem] sm:w-[14rem] sm:h-[14rem] xl:w-[9rem] xl:h-[9rem]">
                          <img
                            src={
                              orgDetails.org_logo
                                ? orgDetails.org_logo
                                : PeviewImage
                            }
                            alt=""
                            className="w-fit h-fit px-2 py-2 object-cover"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col mt-3 xl:mt-0 mb-3">
                    <div className="text-lg font-medium text-gray-900">
                      URLs
                    </div>
                    <div className="text-xs font-light text-[#3A3A3A]">
                      Add links to your social media profiles.
                    </div>
                  </div>
                  <div className="mb-4">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor="organisation_name"
                    >
                      LinkedIn
                    </label>
                    <input
                      type="text"
                      id="org_linkedin_url"
                      value={orgDetails.org_linkedin_url}
                      onChange={handleInputChange}
                      className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="e.g. https://linkedIn/credissuer"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor="organisation_name"
                    >
                      Twitter
                    </label>
                    <input
                      type="text"
                      id="org_twitter_url"
                      value={orgDetails.org_twitter_url}
                      onChange={handleInputChange}
                      className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="e.g. https://twitter/credissuer"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor="organisation_name"
                    >
                      Instagram
                    </label>
                    <input
                      type="text"
                      id="org_instagram_url"
                      value={orgDetails.org_instagram_url}
                      onChange={handleInputChange}
                      className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="e.g. https://instagram/credissuer"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor="organisation_name"
                    >
                      Facebook
                    </label>
                    <input
                      type="text"
                      id="org_facebook_url"
                      value={orgDetails.org_facebook_url}
                      onChange={handleInputChange}
                      className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="e.g. https://facebook/credissuer"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor="organisation_name"
                    >
                      YouTube
                    </label>
                    <input
                      type="text"
                      id="org_youtube_url"
                      value={orgDetails.org_youtube_url}
                      onChange={handleInputChange}
                      className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="e.g. https://youtube/credissuer"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor="organisation_name"
                    >
                      WhatsApp
                    </label>
                    <input
                      type="text"
                      id="org_whatsapp_url"
                      value={orgDetails.org_whatsapp_url}
                      onChange={handleInputChange}
                      className="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="e.g. https://whatsapp/credissuer"
                    />
                  </div>
                </div>
                <div className="w-full xl:w-[50%] rounded-xl">
                  <div className="w-full flex justify-center items-center">
                    <div className="flex justify-center items-center mt-[0rem] xl:mt-[4.7rem] w-[9rem] h-[9rem] sm:w-[14rem] sm:h-[14rem] xl:w-[14rem] xl:h-[14rem]">
                      <img
                        src={
                          orgDetails.org_did_image
                            ? orgDetails.org_did_image
                            : qrCodeIcon
                        }
                        alt=""
                        className={`w-fit h-fit object-cover ${
                          orgDetails.org_did_image
                            ? "px-2 py-2"
                            : "animate-pulse mb-2"
                        }`}
                      />
                    </div>
                  </div>
                  <div className="sub-container1 mt-[2.6rem] px-[0.5rem] pb-[0.5rem] sm:px-[1.1rem] sm:pb-[1.1rem]">
                    <div className="flex flex-col sm:flex-row min-h-[5.5rem] sm:min-h-[7rem] items-center justify-between w-full bg-cfIssuedCredentialTemplateCardDdBG px-3 py-2.5 rounded-[0.25rem]">
                      <div className="flex-1 w-full">
                        <p className="text-xs sm:text-cfIssuedCredentialTemplateCardDdText w-full break-all leading-relaxed">
                          {orgDetails.org_did_key ? (
                            orgDetails.org_did_key
                          ) : (
                            <div class="animate-pulse flex flex-col w-full space-y-6 py-1">
                              <div class="space-y-3">
                                <div class="h-3 bg-[#ACACAC] rounded-xl w-full"></div>
                                <div class="grid grid-cols-3 gap-4">
                                  <div class="h-3 bg-[#ACACAC] rounded-xl col-span-2"></div>
                                  <div class="h-3 bg-[#ACACAC] rounded-xl col-span-1"></div>
                                </div>
                              </div>
                            </div>
                          )}
                        </p>
                      </div>
                      <div className="relative ">
                        {copied ? (
                          <img
                            src={correct}
                            alt=""
                            className="h-7 w-7 sm:h-8 sm:w-8"
                          />
                        ) : (
                          orgDetails.org_did_key && (
                            <img
                              src={Copy}
                              alt=""
                              className="cursor-pointer h-8 w-8 sm:h-9 sm:w-9"
                              onClick={(e) => {
                                handleCopyClick(e, orgDetails.org_did_key);
                                Toast({
                                  type: "success",
                                  title: "Success",
                                  message: "DID:key Copied!",
                                });
                              }}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 ml-3">
                <BtnSave
                  Display_Icon={CgAttachment}
                  TriggerBtn={handleSave}
                  DisplayText={"Save"}
                  type="submit"
                  formData={isFormDataChanged}
                />
              </div>
            </div>
          )}
          {selectedMenu === "Organization Details" && (
            <div className="w-full 2xl:w-[50%] rounded-xl p-3">
              <div className="text-xl font-semibold text-gray-900">
                <p>LinkedIn Organization Details</p>
              </div>
              <div className="flex flex-col w-full h-full justify-between">
                <div className="flex w-full justify-center items-center my-[2rem]">
                  <img src={LinkedInLiveIcon} height={150} width={150} alt="" />
                </div>
                <div>
                  <div class="mb-4">
                    <div className="flex">
                      <label
                        class="flex mb-2 text-sm font-medium text-gray-900"
                        htmlFor="org_domain"
                      >
                        Organization ID
                      </label>
                    </div>
                    <input
                      type="text"
                      id="org_linkedin_code"
                      value={orgDetails.org_linkedin_code}
                      onChange={handleInputChange}
                      class="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="e.g. 1337"
                    />
                  </div>
                  <div class="mb-4">
                    <div className="flex">
                      <label
                        class="flex mb-2 text-sm font-medium text-gray-900"
                        htmlFor="org_domain"
                      >
                        Organization name
                      </label>
                    </div>
                    <input
                      type="text"
                      id="org_linkedin_name"
                      value={orgDetails.org_linkedin_name}
                      onChange={handleInputChange}
                      class="bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="e.g. Credissuer"
                    />
                  </div>
                  <div className="mb-4">
                    <BtnSave
                      Display_Icon={CgAttachment}
                      TriggerBtn={handleSave}
                      DisplayText={"Save"}
                      type="submit"
                      formData={isFormDataChanged}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedMenu === "Notification Settings" && (
            <aside className="flex flex-col gap-1 p-3 w-full lg:w-1/2 xl:w-[50%] 2xl:w-[35%]">
              <section className="flex flex-col bg-white lg:w-full md:grow xs:pt-8 xs:pb-8 lg:pb-2 rounded-xl">
                <header className="text-xl font-semibold leading-8 mb-4 text-gray-900">
                  Notification Settings
                </header>

                <SettingOption
                  key={"toggle_whatsapp"}
                  toggleStatus={orgDetails.enable_whatsapp_notification}
                  heading={"Whatsapp"}
                  subheading={"Send credentials via WhatsApp"}
                  onclickFunction={() =>
                    handleToggleChange("enable_whatsapp_notification")
                  }
                  label={"WhatsApp"}
                  description="Send credentials via whatsApp"
                />
                <SettingOption
                  key={"toggle_email"}
                  toggleStatus={orgDetails.enable_email_notification}
                  heading={"Email"}
                  subheading={"Send credentials via Email"}
                  onclickFunction={() =>
                    handleToggleChange("enable_email_notification")
                  }
                  label={"Email"}
                  description="Send credentials via email"
                />
              </section>
              <div className="mb-4">
                <BtnSave
                  Display_Icon={CgAttachment}
                  TriggerBtn={handleSave}
                  DisplayText={"Save"}
                  type="submit"
                  formData={isFormDataChanged}
                />
              </div>
            </aside>
          )}
          {selectedMenu === "Customize QR Code" && (
            <div className="rounded-xl p-3">
              <div className="text-xl font-semibold text-gray-900">
                <p>Customized QR Code</p>
              </div>
              <div className="flex flex-col lg:flex-row w-full justify-start gap-5 mt-[1rem]">
                <div className="w-full lg:w-[40%] flex-wrap">
                  <div className="items-center flex-initial w-full mt-[0rem] xl:mt-[4.7rem]">
                    <LogoUpload
                      id="qr-code-upload"
                      logoHeading={"Click to upload"}
                      logoSubHeading={"or drag and drop"}
                      altText="Upload QR code image"
                      onFileUpload={handleQrCodeUpload}
                    />
                  </div>
                </div>
                <div className="relative w-[9rem] h-[9rem] sm:w-[15rem] sm:h-[15rem] text-xs font-medium leading-4 text-center border-neutral-200 text-slate-700">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/7bab289eb1d2dafd22127a64b9c6db191467abedc12a3513148d603bfb431595?apiKey=1ca7de1f58f745cd95efc0640d2ef737&"
                    alt="QR code placeholder"
                    className="w-full h-full object-cover border rounded-xl"
                  />
                  <div className="absolute inset-0 flex justify-center items-center">
                    <div className="flex justify-center items-center w-[55px] h-[55px] sm:w-[80px] sm:h-[80px] bg-white ">
                      <img
                        src={
                          orgDetails.org_qrcode_logo
                            ? orgDetails.org_qrcode_logo
                            : PeviewImage
                        }
                        alt=""
                        className="w-full h-full object-cover border rounded-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <BtnSave
                  Display_Icon={CgAttachment}
                  TriggerBtn={handleSave}
                  DisplayText={"Save"}
                  type="submit"
                  formData={isFormDataChanged}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SettingTemplate;
