import React, { useEffect, useState, useContext } from "react";
import { Formio } from "@formio/react";
import { useNavigate, useParams } from "react-router-dom";
// import { useParams } from 'react-router-dom';
import EntityConstants from "../../configs/EntityConstants";
import Toast from "../Toast/Toast";
import { POST } from "../../service/CRUDService";
import FullScreenLoader from "../FullScreenLoader/FullScreenLoader";
import CfLoader from "../CfLoader/CfLoader";
const FormioSingleUpload = ({
  toggleUploadMethod,
  URL,
  formFieldsChanged,
  transaction_name,
  isToggleOn,
  creditCount,
  isPostPaid,
}) => {
  const { _1, credential_template_id, issuer_credential_template_id } =
    useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const hasSufficientCredits = isPostPaid || creditCount > 0;

  const navigate = useNavigate();

  const onSubmitHandle = async (transaction_name, sub) => {
    //Submit-> only Preview for Submit form
    console.log("transaction_namesfdjgsfjdsfdsfjk : ", transaction_name);
    const payload = sub;
    try {
      setIsSubmitting(true);
      const response = await POST(
        `${EntityConstants.POST_CREDENTIALS_SINGLE_ISSUANCE}${issuer_credential_template_id}`,
        payload
      );
      const data = await response.json();
      if (response.ok) {
        setIsSubmitting(false);
        Toast({
          type: "success",
          title: "Success",
          message: data.message,
        });
        //? after toster
        //!redirect to home
        navigate(`/issuer/transaction/${data.transaction_id}`);
      } else {
        console.log("The data message is >>", data.message);
        Toast({
          type: "error",
          title: "Error",
          message: data.message,
        });
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsSubmitting(false);
    }
  };

  const disableSubmitButton = (form) => {
    const submitButton = form.element.querySelector('button[type="submit"]');
    if (submitButton) {
      submitButton.disabled = !hasSufficientCredits;
    }
  };

  useEffect(() => {
    URL &&
      fetch(URL)
        .then((resp) => resp.json())
        .then((data) => {
          if (!transaction_name) return;

          Formio.createForm(document.getElementById("formio"), data).then(
            (form) => {
              form.submission = {
                data: isToggleOn ? { ...URL.data } : {},
              };
              form.on("change", formFieldsChanged);
              form.on("submit", (sub) => {
                if (hasSufficientCredits) {
                  onSubmitHandle(transaction_name, sub);
                } else {
                  Toast({
                    type: "warning",
                    title: "Insufficient Credits",
                    message:
                      "You do not have enough credits to issue credentials",
                  });
                  disableSubmitButton(form);
                }
              });
              disableSubmitButton(form);

              setIsLoading(false);
            }
          );
        });
  }, [URL, transaction_name, isToggleOn, hasSufficientCredits]);

  return (
    <div className="w-full">
      <div id="formio" className="flex flex-col w-full ">
        <div className="flex justify-center items-center ">
          <CfLoader />
        </div>
      </div>
      <div className="p-2">
        <p className="flex items-center  before:content-['*'] before:text-lg before:font-semibold before:text-red-600 drop-shadow-lg text-black">
          To issue multiple certificates&nbsp;
          <p
            className="text-cfPrimary cursor-pointer hover:underline hover:duration-75"
            onClick={() => toggleUploadMethod(true)}
          >
            Click here!
          </p>
        </p>
      </div>
      {isSubmitting && <FullScreenLoader />}
    </div>
  );
};

export default FormioSingleUpload;
