// Images
import Lang from "../../images/Language Translate2.0.png";
import Favicon from "../../images/Favicon.svg";
// React
import { useState, useEffect, useContext } from "react";
import { Disclosure, Menu } from "@headlessui/react";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
//Services
import { GET, LOGOUT } from "../../service/CRUDService";
// Constants
import EntityConstants from "../../configs/EntityConstants";
// Store
import AuthContext from "../../store/auth-context";
// React Icons
import { BiLogOutCircle, BiSolidUserCircle } from "react-icons/bi";
import Toast from "../../common/Toast/Toast";
import { useTranslation } from "react-i18next";
import AppConstants from "../../configs/AppConstants";
import { googleLogout } from "@react-oauth/google";
import { useGoogleOneTapLogin } from "@react-oauth/google";
import { GLogin } from "../../service/OAuthLogin";
import ProfileIcon from "../../resources/icons/profile-circle.svg";
import NotificationIcon from "../../resources/icons/notification.svg";
import HelpIcon from "../../resources/icons/message-question.svg";
import { MLogin } from "../../service/OAuthLogin";
import WalletIcon from "../../resources/icons/Wallet.svg";
import ReloadIcon from "../../resources/icons/Reload.svg";

//main NavBar component
export default function Navbar({ TriggerLoginModuleBtn, chooseSignUp_SignIn }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const home = "/";
  const [code, setCode] = useState("");
  const [isRotating, setIsRotating] = useState(false);
  const [isPostPaid, setIsPostPaid] = useState(true);

  const handleReloadClick = () => {
    setIsRotating(true);
    setTimeout(() => setIsRotating(false), 1000);
    if (handleCurrentUser()) {
      Toast({
        type: "success",
        title: "Success!",
        message: "Credits refreshed successfully !",
      });
    }
  };

  useGoogleOneTapLogin({
    disabled: authContext.isLoggedIn,
    onSuccess: async (credentialResponse) => {
      const login = await GLogin(credentialResponse, "success");
      if (login === true) {
        authContext.login() && authContext.googlelogin();
      }
    },
    onError: (error) => GLogin(error, "failed"),
    googleAccountConfigs: {
      client_id: AppConstants.CLIENT_ID,
    },
  });
  const languages = [
    { value: "en", label: `${t("navbar.lang.en")}` },
    { value: "es", label: `${t("navbar.lang.es")}` },
    { value: "swa", label: `${t("navbar.lang.swa")}` },
  ];

  const defaultLanguageValue = i18n.language; // Assuming i18n.language is a string representing the language value

  // Find the language object that matches the defaultLanguageValue
  const defaultLanguageObject = languages.find(
    (language) => language.value === defaultLanguageValue
  );

  // Set the default language object in the useState
  const [language, setLanguage] = useState(
    defaultLanguageObject || languages[0]
  );

  const logout = async () => {
    try {
      authContext.isGoogleLogin && googleLogout(); // ONLY DO IT IF GOOGLE LOGIN IS USED //! NOW DOING IT ALL TYPES OF LOGIN
      const response = await LOGOUT(EntityConstants.LOGOUT);

      if (response.ok) {
        authContext.createUserProfileDetails({});
        localStorage.clear();
        authContext.logout();
        authContext.createUserProfile(null);
        authContext.createTenantName(null);
        authContext.createTenantId(null);
        location.pathname.startsWith("/issuer") && navigate("/");
        location.pathname === "/" && window.location.reload();
        if (location.pathname === "/discovery") {
          navigate("/");
          window.location.reload();
        }
      }
    } catch (error) {
      Toast({
        type: "error",
        title: "Logout Failed !",
        message: error,
      });
    }
  };

  const handleCurrentUser = async (loginType = null) => {
    try {
      const response = await GET(EntityConstants.CURRENT_USER);

      if (response.ok) {
        const data = await response.json();
        if (data !== authContext.userDetails) {
          //use and complete logic here
          authContext.login();
          if (loginType === "password") {
            authContext.createUserProfileDetails(data);
          } else if (loginType === "google") {
            authContext.createUserProfileDetails({
              first_name: data.given_name,
              last_name: data.family_name,
              email: data.email,
              profile_photo: data.image_url,
              available_credits: data.available_credits,
              mode_of_payment: data.mode_of_payment,
            });
            if (data.mode_of_payment === "post_paid") {
              setIsPostPaid(true);
            }
          }

          authContext.createUserProfile(data.user_profile_id);
          authContext.createTenantName(data.tenant_name);
          authContext.createTenantId(data.tenant_id);
          setIsPostPaid(data.mode_of_payment === "post_paid");
        } else {
          const errorData = await response.json();
          Toast({
            type: "error",
            title: "Error",
            message:
              errorData.message ||
              "Failed to get User Details\nPlease Retry...",
          });
        }
      }
    } catch (error) {
      // Handle unexpected errors
      console.error("Failed to fetch current user:", error);
      Toast({
        type: "error",
        title: "Error!",
        message: "An unexpected error occurred. Please try again.",
      });
    }
  };

  const handleMicrosoftRedirect = async () => {
    try {
      const codeVerifier = sessionStorage.getItem("pkce_code_verifier");

      const response = await fetch(EntityConstants.MICROSOFT_AUTH_CODE, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          client_id: AppConstants.MICROSOFT_CLIENT_ID,
          scope: AppConstants.MICROSOFT_SCOPE,
          code: code,
          redirect_uri: AppConstants.HTTPS_HOST,
          grant_type: AppConstants.MICROSOFT_GRANT_TYPE,
          code_verifier: codeVerifier,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const loginStatus = await MLogin(data.access_token);
        if (loginStatus) {
          authContext.login() && authContext.microsoftlogin();
        }
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch access token: ", errorData);
      }
    } catch (error) {
      console.error("Error during token exchange: ", error);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setCode(urlParams.get("code"));
  }, []);

  useEffect(() => {
    code && handleMicrosoftRedirect();
  }, [code]);

  const path = location.pathname;

  useEffect(() => {
    const path = location.pathname;

    // Check if the path is not equal to "/" and doesn't start with "/discover/"
    if (!path.startsWith("discover/")) {
      // Handle the redirect or any other action you want to perform
      //if check for auth_token if exists then call handleCurrentUser().
      localStorage.getItem("auth_token") && handleCurrentUser("password");
    }
    if (
      path.startsWith("/issuer/certificate/") !== true &&
      path.startsWith("/certificate/") !== true
    ) {
      //clear these on any other page apart from certificate/ page
      sessionStorage.removeItem("details_form_data");
      sessionStorage.removeItem("certificate_title");
    }
  }, [authContext.isLoggedIn, location.pathname]);

  useEffect(() => {
    if (authContext.isGoogleLogin) {
      localStorage.getItem("auth_token") && handleCurrentUser("google");
    }
  }, [authContext.isGoogleLogin]);

  useEffect(() => {
    i18n.changeLanguage(language.value);
  }, [language]);

  return (
    <div className="sticky top-0">
      <Disclosure as="nav" className="bg-cfSecondary border-b-[1.5px]">
        {({ open }) => (
          <>
            <div className="mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex h-[5rem] items-center justify-between">
                <NavLink
                  to={home}
                  className={
                    "flex gap-4 items-center w-[35%] hover:text-inherit hover:no-underline"
                  }
                >
                  <img className="w-64" src={Favicon} alt="Credflow" />
                </NavLink>
                <div className="flex items-center w-[65%] gap-2 md:gap-5 justify-end">
                  {authContext.isLoggedIn && !isPostPaid && (
                    <div className="group relative flex max-w-max drop-shadow-xl flex-col items-center justify-center">
                      <img
                        className="cursor-pointer"
                        src={WalletIcon}
                        height={35}
                        width={35}
                        alt=""
                      />
                      <div
                        className={`absolute left-1/2 top-[2.2rem] ml-auto mr-auto min-w-max -translate-x-1/2 scale-0 transform rounded-lg px-3 py-2 text-xs font-medium transition-all duration-500 group-hover:scale-100`}
                      >
                        <div className="flex flex-col items-center">
                          <div
                            className="relative h-0 w-0 border-l-4 border-r-4 border-b-4 border-l-transparent border-r-transparent"
                            style={{ borderBottomColor: "#4F4F4F" }}
                          >
                            <div
                              className="absolute top-[2px] left-[-4px] h-0 w-0 border-l-4 border-r-4 border-b-4 border-l-transparent border-r-transparent"
                              style={{ borderBottomColor: "#ffffff" }}
                            ></div>
                          </div>
                          <div
                            className={`rounded px-2 py-3 text-xs border border-gray-900 text-black whitespace-normal break-words max-w-[275px] md:max-w-[400px]`}
                            style={{ backgroundColor: "#ffffff" }}
                          >
                            <div className="flex items-center ">
                              <div className="w-[22%] text-center text-lg text-[#3538CD] font-bold cursor-default">
                                {authContext.userDetails.available_credits
                                  ? authContext.userDetails.available_credits
                                  : 0}
                              </div>
                              <div className="flex flex-col w-[78%] gap-2 ml-1 cursor-default">
                                <div className="flex h-5 items-center gap-3">
                                  <div className="text-[#4F5768] font-bold text-base">
                                    CREDIT BALANCE
                                  </div>
                                  <div>
                                    <img
                                      src={ReloadIcon}
                                      height={14}
                                      width={14}
                                      alt="Reload Icon"
                                      className={`cursor-pointer transition-transform ${
                                        isRotating ? "animate-rotate" : ""
                                      }`}
                                      onClick={handleReloadClick}
                                    />
                                  </div>
                                </div>
                                <div className="flex justify-start items-center text-[#4F5768]">
                                  Credits will be utilized to generate
                                  verifiable credentials.
                                </div>
                              </div>
                              {/* <div className="bg-[#3538CD] cursor-pointer px-[0.5rem] py-[0.7rem] rounded-lg font-semibold text-md text-white">
                                Buy Credits
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="flex items-center">
                    {authContext.isLoggedIn &&
                    Object.values(authContext.userDetails).length !== 0 ? (
                      <Menu as="div" className="relative ">
                        <div>
                          <Menu.Button className="flex max-w-xs items-center text-md text-cfNavBarText focus:outline-none pr-4">
                            {authContext.userDetails.profile_photo ? (
                              <span className="flex items-center gap-1">
                                <img
                                  className="h-8 w-8 rounded-full"
                                  src={authContext.userDetails.profile_photo}
                                  alt=""
                                />
                                <p>{authContext.userDetails?.first_name}</p>
                                <p>{authContext.userDetails?.last_name}</p>
                              </span>
                            ) : (
                              <span className="flex items-center gap-1">
                                <img
                                  src={ProfileIcon}
                                  alt=""
                                  srcSet=""
                                  className="text-gray-400 w-7"
                                />
                                <p>{authContext.userDetails?.first_name}</p>
                                <p>{authContext.userDetails?.last_name}</p>
                              </span>
                              // <BiSolidUserCircle size={35} className="text-gray-400"/>
                            )}
                          </Menu.Button>
                        </div>
                        <Menu.Items className="flex flex-col text-gray-500 absolute right-0 z-10 mt-2 pt-3 px-2 text-md w-max origin-top-right rounded-md bg-cfSecondary py-1 shadow-lg ring-1 ring-cfSecondary ring-opacity-5 focus:outline-none">
                          <div className="">
                            <hr />
                            <Menu.Item className="px-2 pb-1 py-2">
                              <NavLink
                                to={path === "/" ? "issuer/profile" : "profile"}
                                className="hover:no-underline hover:text-gray-600 text-cfNavBarText flex items-center gap-1"
                              >
                                <div className="w-4 h-4 rounded-full flex items-center justify-center">
                                  {
                                    <img
                                      src={
                                        authContext.userDetails.profile_photo ||
                                        ProfileIcon
                                      }
                                      alt=""
                                      srcset=""
                                      className="w-4 h-4 rounded-full"
                                    />
                                  }
                                </div>
                                &nbsp;
                                {authContext.userDetails?.first_name}&nbsp;
                                {authContext.userDetails?.last_name}
                              </NavLink>
                            </Menu.Item>
                            <hr />
                            <Menu.Item className="px-2 py-1">
                              <button
                                type="button"
                                className="flex items-center gap-1 hover:text-gray-600 text-cfNavBarText bg-cfSecondary focus:outline-none"
                                onClick={logout}
                              >
                                <BiLogOutCircle />
                                <p className="ml-1">Sign out</p>
                              </button>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Menu>
                    ) : (
                      <div className="flex items-center gap-3 justify-between">
                        <div>
                          <button
                            className="flex flex-col max-w-xs items-end font-CfPrimaryFont text-sm sm:text-base text-cfSignUpText leading-0 sm:leading-6 font-medium focus:outline-none"
                            onClick={() => {
                              chooseSignUp_SignIn(false);
                              TriggerLoginModuleBtn();
                            }}
                          >
                            <p>Sign up to try your first template for free!</p>
                          </button>
                        </div>
                        <div>
                          <button
                            className="flex max-w-xs relative items-center justify-center text-lg font-semibold text-cfSecondary focus:outline-none bg-cfPrimaryButton py-2.5 px-4 w-[5.5rem] h-[2.9rem] rounded-xl"
                            onClick={() => {
                              chooseSignUp_SignIn(true);
                              TriggerLoginModuleBtn();
                            }}
                          >
                            <p className="text-base absolute">Sign In</p>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
    </div>
  );
}
