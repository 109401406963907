import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import HandleImgError from "../../../utils/HandleURLImgError";
import DraftsRevokeModal from "../../../common/DraftsRevokeModal/DraftsRevokeModal";
import { ReactComponent as EditIcon } from "../../../resources/icons/edit.svg";
import { ReactComponent as FlyingMail } from "../../../resources/icons/flying-mail.svg";
import { ReactComponent as DeleteIcon } from "../../../resources/icons/delete.svg";
import ToolTip from "../../../common/ToolTip/ToolTip";

const DraftsCredentialTemplateCard = (props) => {
  const {
    name,
    description,
    credential_template_id,
    issuer_credential_template_id,
    tagsList,
    thumbnail_image,
    navigate_url,
    removeCredentialFromList,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(null);
  const navigate = useNavigate();

  const toggleModal = (id) => {
    setIsModalOpen(id);
  };

  return (
    <div
      className={`flex flex-col gap-3 bg-cfSecondary border border-cfCardBorder  ${
        credential_template_id &&
        "cursor-pointer hover:bg-cfCardHover hover:scale-105"
      } transition-all duration-500 rounded-2xl overflow-hidden w-[19.125rem]`}
      name={credential_template_id}
      onClick={() => {
        credential_template_id && navigate_url && navigate(navigate_url);
      }}
    >
      <div className="flex flex-col h-full">
        <div className="flex flex-col h-fit">
          <div
            className={`flex items-center h-[12.5rem] border-b w-full bg-cfSecondary ${
              !thumbnail_image && "w-full animate-pulse bg-zinc-300"
            }`}
          >
            <img
              src={
                (thumbnail_image || thumbnail_image === null) && thumbnail_image
              }
              onError={(e) => HandleImgError(e, thumbnail_image)}
              alt=""
              className="w-full h-full object-cover object-top"
            />
          </div>
        </div>

        <div className="flex flex-col gap-4 h-[7rem] m-4">
          <div>
            <div className="text-cfHeadingText font-semibold text-xl">
              <p
                className={`line-clamp-1 ${
                  !name && "h-8 w-full animate-pulse bg-zinc-300 rounded-md"
                }`}
              >
                {name}
              </p>
            </div>
            <div>
              <p
                className={`text-base line-clamp-2 ${
                  !description &&
                  "h-8 w-full animate-pulse bg-zinc-300 text-gray-400 rounded-md"
                }`}
              >
                {description}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-3 divide-x divide-slate-600 divide-solid">
            <div className="flex justify-center items-center group">
              <ToolTip
                component={
                  <EditIcon
                    height={20}
                    width={20}
                    className="text-black hover:text-cfPrimaryLight transition-colors duration-500"
                    onClick={(e) => {
                      e.stopPropagation();
                      credential_template_id &&
                        navigate_url &&
                        navigate(navigate_url);
                    }}
                  />
                }
                message={"Edit Template"}
                tooltipPosition="top-[-3rem]"
                bgColor="#3538CD"
                tooltipWidth={"max-w-[130px]"}
              />
            </div>
            <div
              className="flex justify-center items-center group"
              onClick={(e) => {
                e.stopPropagation();
                navigate(
                  `/issuer/certificate/1/${credential_template_id}/${issuer_credential_template_id}`
                );
              }}
            >
              <ToolTip
                component={
                  <FlyingMail
                    height={20}
                    width={20}
                    className="text-black hover:text-cfPrimaryLight transition-colors duration-500"
                  />
                }
                message={"Issue Certificate"}
                tooltipPosition="top-[-3rem]"
                bgColor="#3538CD"
                tooltipWidth={"max-w-[130px]"}
              />
            </div>
            <div
              className="flex justify-center items-center group"
              onClick={(e) => {
                e.stopPropagation();
                toggleModal(issuer_credential_template_id);
              }}
            >
              <ToolTip
                component={
                  <DeleteIcon
                    className="fill-white transition-colors duration-500 hover:fill-red-500"
                    height={20}
                    width={20}
                  />
                }
                message={"Delete Draft"}
                tooltipPosition="top-[-3rem]"
                bgColor="#DC2626"
                tooltipWidth={"max-w-[130px]"}
              />
            </div>
          </div>

          {/* <div className="flex gap-1"> //! Can be used for tags in future
            {tagsList ? (
              tagsList.slice(0, 2).map((item, index) => (
                <p
                  key={index}
                  className=" text-xs bg-cfCardTagFill text-cfCardTagText font-medium group-hover:bg-cfTagFillHover group-hover:text-cfSecondary w-fit px-2 py-1 rounded-full h-fit"
                >
                  {item}
                </p>
              ))
            ) : (
              <>
                <p className="h-5 w-4/12 animate-pulse text-xs bg-zinc-300 px-2 py-1 rounded-full"></p>
                <p className="h-5 w-5/12 animate-pulse text-xs bg-zinc-300 px-2 py-1 rounded-full"></p>
              </>
            )}
          </div> */}
        </div>
      </div>
      <DraftsRevokeModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        removeCredentialFromList={removeCredentialFromList}
      />
    </div>
  );
};
export default DraftsCredentialTemplateCard;
